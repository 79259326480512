<template>
    <div class="mainform">
        <!-- 供应商网上发票详情 -->
        <div class="form">
            <div class="header">网上发票信息<span @click="back" class="back">返回></span> </div>
            <online-invoice-info ref="onlineInfo" :invoiceInfodata="invoiceInfodata"></online-invoice-info>
            <div class="header">网上发票明细
              <span class="showOff">
                <el-button @click="open" size="small" type="text">{{isOpen?"展开":"收起"}}
                  <i :class="[{'el-icon-arrow-down':isOpen},{'el-icon-arrow-up':!isOpen}]"></i>
                </el-button>
              </span>
            </div>
            <global-table v-if="isOpen" class=" bascform" max-height="350" @diashow="diaShow" :tableField="tableField" :tableData="tableData" ></global-table>
            <div class="header">物料汇总
              <span class="showOff">
                <el-button @click="show" size="small" type="text">{{isShow?"展开":"收起"}}
                  <i :class="[{'el-icon-arrow-down':isShow},{'el-icon-arrow-up':!isShow}]"></i>
                </el-button>
              </span>
            </div>
            <global-table v-if="isShow" class="bascform"  max-height="350" :tableField="MaterialData" :tableData="materialList" ></global-table>
            <div class="header">税务发票匹配</div>
            <div class=" bascform">
                <div class="tableList" >
                  <div class="table">
                    <el-table  style="width: 100%" :data="taxinvoicedata">
                        <el-table-column prop="invoiceCode" label="发票代码" > </el-table-column>
                        <el-table-column prop="invoiceNo" label="发票号码" width='200px'></el-table-column>
                        <el-table-column prop="totalAmount" label="含税金额" > </el-table-column>
                        <el-table-column prop="invoiceAmount" label="未税金额" ></el-table-column>
                        <el-table-column prop="taxAmount" label="税额"></el-table-column>
                        <el-table-column prop="invoiceDate" label="开票日期"> </el-table-column>
                        <el-table-column prop="invoiceStatus" label="发票状态">
                          <template #default="scope">
                            {{scope.row.invoiceStatus==0?'正常'
                            :scope.row.invoiceStatus==1?'失控':
                            scope.row.invoiceStatus==2?'作废 ':
                            scope.row.invoiceStatus==3?'红冲':
                            scope.row.invoiceStatus==4?'异常':
                            scope.row.invoiceStatus}}
                          </template>
                        </el-table-column>
                        <el-table-column prop="isEleSignFor" label="签收状态">
                          <template #default="scope">
                            {{scope.row.isEleSignFor==0?'已签收'
                            :scope.row.isEleSignFor==1?'未签收': '取消签收'}}
                          </template>
                        </el-table-column>
                        <el-table-column prop="qsDate" label="签收日期"> </el-table-column>
                        <el-table-column prop="remark" label="备注"> </el-table-column>
                    </el-table>
                  </div>
            </div>
            </div>
        </div>
        <!-- 状态0 待匹配  可作废-->
        <div class="action" v-if="invoiceInfodata.onlineInvoiceStatus == 0">
          <el-button class="submit" type="primary" size="medium" @click="matchBtn">匹配发票</el-button>
          <el-button class="submit" type="primary" size="medium" @click="cancelBtn">作废</el-button>
        </div>
        <!-- 状态为 0待匹配 3取消记账  5代签收   可以作废 -->
        <div class="action" v-if="invoiceInfodata.onlineInvoiceStatus == 3">
          <el-button class="submit" type="primary" size="medium" @click="cancelBtn">作废</el-button>
        </div>
        <!-- 状态为 4   可以作废 -->
        <div class="action" v-if="invoiceInfodata.onlineInvoiceStatus == 4">
          <el-button class="submit" type="primary" size="medium" @click="cancelBtn">作废</el-button>
        </div>
        <!-- 状态为 5 待签收   可重新匹配  可作废 -->
        <div class="action" v-if="invoiceInfodata.onlineInvoiceStatus == 5">
          <!-- <el-button class="submit" type="primary" size="medium" @click="Tomatch">重新匹配</el-button> -->
          <el-button class="submit" type="primary" size="medium" @click="cancelBtn">作废</el-button>
        </div>
        <dialog-box  ref="addDialog" dialogWidth="70%" :invoiceInfodata="invoiceInfodata" :invoiceData="invoiceData" :dialogShow="dialogShow" @handleClose='handleClose' @confirmAdd= "confirmAdd" title="匹配发票" componentName="SupplierinvoiceDetaildialog"
            :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
        </dialog-box>
        <dialog-box  ref="dialogbox" dialogWidth="50%" :showData="showData" :dialogShow="MVoucherNoDialogdialogShow" @handleClose='handleClose'  title="物料凭证" componentName="MVoucherNoDialog"
                :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]">
        </dialog-box>
    </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import { tableField, tableData, MaterialData } from './js/supplierinvoiceDetail'
import { requestForm, request } from '@/assets/js/http.js'
import OnlineInvoiceInfo from '@/views/financialManagement/supplierFinancialMg/supplierInvoice/OnlineInvoiceInfo'
export default {
  components: { ...Component.components, OnlineInvoiceInfo },
  name: 'SupplierinvoiceDetail',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      MaterialData: MaterialData,
      materialList: [],
      dialogShow: false,
      isShow: true,
      isOpen: true,
      MVoucherNoDialogdialogShow: false,
      form: {},
      showData: {},
      invoiceInfodata: {},
      invoiceData: [],
      taxinvoicedata: [],
      ids: '', // 匹配发票列表的id
      id: '' // 网上发票信息的id
    }
  },
  mounted () {
    this.invioceList()
  },
  methods: {
    invioceList () {
      const id = this.$route.query.id
      requestForm('/api/reconciliation/invoiceTotal/queryOne?id=' + id, 'post').then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.reconciliationList
          this.invoiceInfodata = res.data
          this.taxinvoicedata = res.data.invoiceList
          this.materialList = res.data.materialList
          this.tableData.forEach(item => {
            this.form.materialVoucherNo = item.materialVoucherNo
          })
          request(`/api/supplier/basicData/getByErpCode/${res.data.billingPartyCode}`, 'get', '').then((res) => {
            if (res.code === '200') {
              this.invoiceInfodata.salerTaxNo = res.data.creditCode
            }
          })
        }
      })
    },
    diaShow (scope) {
      var number = scope.row.materialVoucherNo
      requestForm('/api/reconciliation/materialDocument/queryVoucherNo?number=' + number, 'post', '').then((res) => {
        if (res.code === '200') {
          this.showData = res.data
          this.MVoucherNoDialogdialogShow = true
        }
      })
    },
    show () {
      this.isShow = !this.isShow
    },
    open () {
      this.isOpen = !this.isOpen
    },
    matchBtn () { // 匹配发票按钮
      this.dialogShow = true
      this.invoiceData = this.$refs.onlineInfo.form
    },
    cancelBtn () { // 作废按钮
      var id = this.$route.query.id
      this.$confirm('确认作废？', '作废', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        requestForm('/api/reconciliation/invoiceInfo/toVoid?id=' + id, 'post', '').then((Response) => {
          if (Response.code === '200') {
            this.$message({
              message: '作废成功',
              type: 'success'
            })
          }
          this.$router.push('/FinancialManagement/Supplierinvoice')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // Tomatch () { // 重新匹配
    //   this.dialogShow = true
    // },
    confirmAdd () { // 匹配发票
      const choicedata = this.$refs.addDialog.$refs.content.selectData
      console.log('choicedata', this.$refs.addDialog.$refs.content.selectData)
      const submitData = {}
      submitData.fid = this.$route.query.id
      submitData.list = choicedata
      submitData.salerTaxNo = this.invoiceInfodata.salerTaxNo
      console.log('submitData.salerTaxNo', submitData.salerTaxNo)
      request('/api/reconciliation/invoiceInfo/matching', 'post', submitData).then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '匹配成功！',
            type: 'success'
          })
          this.invioceList()
        }
        this.dialogShow = false
      })
    },
    handleClose () {
      this.dialogShow = false
      this.MVoucherNoDialogdialogShow = false
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
  @import '@/assets/css/tableList.scss';
  .back{
    float: right;
    color: #00aaff;
    font-size: 16px;
    cursor: pointer;
  }
  .tableList{
      padding: 0px;
  }
  .showOff{
  float: right;
}
</style>
